import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ASSETS from 'enums/assets';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const { t } = useTranslation(); //i18n
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        return (window.location.href = 'https://ealgorithm.net/contact-us/');
      case 1:
        return navigate('/privacy-policy');
      case 2:
        return navigate('/terms-and-conditions');
      case 3:
        return navigate('/cookie-policy');
      default:
        return null;
    }
  };

  return (
    <List
      component="nav"
      sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] }, '& img': { width: '18px' } }}
    >
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <img src={ASSETS.ICONS.INFO.INFO} alt="INFO" />
        </ListItemIcon>
        <ListItemText primary={t('System.lb_support')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <img src={ASSETS.ICONS.INFO.LOCK} alt="LOCK" />
        </ListItemIcon>
        <ListItemText primary={t('System.lb_privacy_policy')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <img src={ASSETS.ICONS.INFO.GRAVEL} alt="GRAVEL" />
        </ListItemIcon>
        <ListItemText primary={t('System.lb_terms_conditions')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <img src={ASSETS.ICONS.INFO.COOKIES} alt="cookie" />
        </ListItemIcon>
        <ListItemText primary={t('System.lb_cookies_policy')} />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
