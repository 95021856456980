import PropType from 'prop-types';
import styles from './QRCodeList.module.css';
import { useTranslation } from 'react-i18next';

//React
import { useState } from 'react';

//Material UI
import { IconButton } from '@mui/material';

//EA Design System
import ItemListButton from 'components/ea-design-system/ItemListButton';

//Assets
import ASSETS from 'enums/assets';

//Components
import DeleteDialog from 'components/DeleteDialog';
import React from 'react';

export default function QRCodeList({ setSelectedQRCode, companyQRCodes, handleDeleteQRCode }) {
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [qrToRemove, setQRToRemove] = useState(null);

  const handleDeleteCompanyQRCode = async (key) => {
    handleDeleteQRCode(key);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      {Object.entries(companyQRCodes).length > 0 && (
        <div>
          <div className={styles.qrListTitle}>{t('QRCode.lb_qr_codes')}</div>
          {Object.entries(companyQRCodes).map(([key, value], index) => (
            <ItemListButton
              key={key}
              title={key}
              index={index}
              onSelectItem={() => {
                setSelectedQRCode([key, value]);
              }}
            >
              <IconButton
                onClick={() => {
                  setQRToRemove(key);
                  setOpenDeleteDialog(true);
                }}
                aria-label="delete"
                size="large"
              >
                <img src={ASSETS.ICONS.ACTIONS.DELETE} alt="delete" />
              </IconButton>
            </ItemListButton>
          ))}
        </div>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        onDelete={() => handleDeleteCompanyQRCode(qrToRemove)}
      />
    </>
  );
}

QRCodeList.propTypes = {
  setSelectedQRCode: PropType.func.isRequired,
  companyQRCodes: PropType.object.isRequired,
  handleDeleteQRCode: PropType.func.isRequired
};
