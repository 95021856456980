import PropTypes from 'prop-types';

//Material-UI / Color
import { Grid, TextField, Autocomplete, FormControl, IconButton, InputAdornment } from '@mui/material';
import color from 'themes/colors';

//Hooks / Utils
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { findLanguageName, findLanguageCode } from 'utils/languageFinder';

//Assets
import ASSETS from 'enums/assets';
import { PlusOutlined } from '@ant-design/icons';

import languages from './languages.json';
import FadeItemList from 'utils/RenderEffects/FadeItemList';

export default function LanguageInput({ form, field }) {
  const [languageCode, setLanguageCode] = useState(null);

  //Add language when selected
  useEffect(() => {
    if (languageCode) {
      createLanguage();
    }
    //eslint-disable-next-line
  }, [languageCode]);

  const createLanguage = () => {
    if (languageCode) {
      // Check if the language is already added
      if (field.value.includes(languageCode)) {
        return;
      }
      // Set default language if it is the first language added
      if (field.value.length === 0) {
        form.setFieldValue('defaultLanguage', languageCode);
      }

      form.setFieldValue(field.name, [...field.value, languageCode]);
      setLanguageCode(null);
    }
  };

  const deleteLanguage = (languageCode) => {
    const newLanguages = field.value.filter((item) => item !== languageCode);
    form.setFieldValue(field.name, newLanguages);

    //Set default language if the deleted language was the default language
    if (form.values.defaultLanguage === languageCode && newLanguages.length > 0) {
      form.setFieldValue('defaultLanguage', newLanguages[0]);
    }
  };

  const { t } = useTranslation();

  return (
    <Grid>
      <FormControl fullWidth>
        <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="Platform">
          {t('Company.lb_language')}:*
        </label>
        <Autocomplete
          disableClearable
          popupIcon={null}
          id="language"
          options={languages.map((option) => option.name)}
          value={languageCode ? findLanguageName(languageCode) : null}
          onChange={(event, newValue) => {
            setLanguageCode(findLanguageCode(newValue));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') createLanguage();
          }}
          renderInput={(params) => (
            <TextField
              error={form.touched.languages && !!form.errors.languages}
              helperText={form.touched.languages ? form.errors.languages : ''}
              placeholder="Select Available Language"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={createLanguage}>
                      <PlusOutlined />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingRight: '9px !important'
                }
              }}
            />
          )}
        />
      </FormControl>

      {/* ==============| List |============== */}

      {field.value?.map((i, index) => (
        <Grid
          key={i}
          sx={{
            maxHeight: '250px',
            overflow: 'scroll',
            padding: '16px 12px',
            bgcolor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
          }}
        >
          <FadeItemList>
            <Grid container wrap="nowrap" sx={{ display: 'flex', height: '48px' }}>
              <Grid item xs={11}>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <div style={{ maxWidth: '260px' }}>
                    <div style={{ fontWeight: '500', fontSize: '16px' }}>{findLanguageName(i)}</div>

                    {form.values.defaultLanguage === i ? (
                      <div
                        style={{
                          color: '#8C8C8C'
                        }}
                      >
                        {t('Company.lb_default')}
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          form.setFieldValue('defaultLanguage', i);
                        }}
                        style={{
                          all: 'unset',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: '#1677ff',
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }}
                      >
                        {t('Company.lb_set_default')}
                      </button>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs sx={{ m: 'auto' }}>
                <IconButton aria-label="add" size="medium" onClick={() => deleteLanguage(i)}>
                  <img src={ASSETS.ICONS.ACTIONS.DELETE} alt="delete" />
                </IconButton>
              </Grid>
            </Grid>
          </FadeItemList>
        </Grid>
      ))}
    </Grid>
  );
}

LanguageInput.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object
};
