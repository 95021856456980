import { Grid } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

//Redux / Context
import { useSelector } from 'react-redux';
import { SnackbarContext } from 'store/contexts/GlobalMessages/SnackbarContext';

//Components
import QRCodeCreate from './components/QRCodeCreate';
import QRCodePreview from './components/QRCodePreview';
import QRCodeList from './components/QRCodeList';

//Services
import { fetchCompanyQRCodes, deleteCompanyQRCode } from 'services/qrCodeRepository';

//Styles
import styles from './GenerateQRCode.module.css';

//Monitoring
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';

export default function GenerateQRCode() {
  const { t } = useTranslation();
  const showSnackbar = useContext(SnackbarContext);

  //=======| Get Selected Company Object From Redux |=======//
  const mainUser = useSelector((state) => state.mainUser);
  const userCompanys = Object.entries(mainUser.userDocument?.companyIDs || []);
  const selectedCompanyID = mainUser.userSelectedCompanyID;
  //=======================================================//

  const [companyQRCodes, setCompanyQRCodes] = useState({});
  const [selectedQRCode, setSelectedQRCode] = useState(null);

  //This will make the QRCode Preview close when a new company is selected or unselected
  useEffect(() => {
    setSelectedQRCode(null);
    if (selectedCompanyID) {
      handleFetchCompanyQRCodes(selectedCompanyID);
    }
    //eslint-disable-next-line
  }, [selectedCompanyID]);

  //[Fetch] the qr codes from the firebase
  const handleFetchCompanyQRCodes = async (companyID) => {
    try {
      if (!companyID) return;
      const data = await fetchCompanyQRCodes(companyID);
      setCompanyQRCodes(data ? data : {});
    } catch (error) {
      showSnackbar(t('Error.lb_error_fetching_qr_codes'), 'error');
      monitoring.error('Error fetching qr codes', error);
    }
  };

  //[Delete] de QR Code from the firebase
  const handleDeleteQRCode = async (key) => {
    try {
      await deleteCompanyQRCode(selectedCompanyID, key, companyQRCodes);
      if (selectedQRCode && selectedQRCode[0] === key) {
        setSelectedQRCode(null);
      }
      showSnackbar(t('Success.lb_qrcode_deleted'), 'success');
      monitoring.breadcrumb('[QRCode Deleted]', `QR code "${key}" deleted successfully`);
      await handleFetchCompanyQRCodes(selectedCompanyID);
    } catch (error) {
      showSnackbar(t('Error.lb_error_deleting_qr_code'), 'error');
      monitoring.error('Error deleting qr code', error);
    }
  };

  //All the keys of the qr codes(Used to compare if the key already exists when creating a new qr code)
  const companyQRCodesKeys = Object.keys(companyQRCodes).map((key) => {
    return key;
  });

  return (
    <Grid container spacing={2} sx={{ display: 'flex', width: '100%', fontFamily: 'Open Sans' }} columns={{ xs: 6, sm: 10, md: 12 }}>
      <Grid item xs={12} md={5}>
        <div className={styles.boxContainer}>
          <QRCodeCreate
            showSnackbar={showSnackbar}
            selectedCompany={selectedCompanyID}
            handleFetchCompanyQRCodes={handleFetchCompanyQRCodes}
            companyQRCodesKeys={companyQRCodesKeys}
            userCompanys={userCompanys}
          />
        </div>
        {selectedCompanyID && Object.entries(companyQRCodes).length > 0 && (
          <div className={styles.boxContainer}>
            <QRCodeList setSelectedQRCode={setSelectedQRCode} companyQRCodes={companyQRCodes} handleDeleteQRCode={handleDeleteQRCode} />
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        {selectedQRCode && <QRCodePreview selectedQRCode={selectedQRCode} />}
      </Grid>
    </Grid>
  );
}
