import * as React from 'react';
import { useState, useEffect, useMemo, useContext } from 'react';
import { refreshToken } from 'zFirebaseConfigs/firebase';
import { useTranslation } from 'react-i18next';

//Material UI / Styled Components
import { Box, Grid, CircularProgress } from '@mui/material';
import { Item } from 'components/StyledComponents';

//Components
import Preview from './components/Preview/index';
import FormComponent from './components/Form';
import CompaniesList from './components/CompaniesListView';

//Services
import {
  createCompany,
  deleteCompany,
  deleteCompanyStorage,
  fetchCompaniesByIds,
  updateCompany,
  uploadBanners,
  uploadLogo
} from 'services/companyRepository';
import { addUserCompany, deleteInAllUsers, updateInAllUsers } from 'services/userRepository';
import { deleteAllItems } from 'services/menuItemRepository';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarContext } from 'store/contexts/GlobalMessages/SnackbarContext';

import { setUserDocument, setUserSelectedCompanyID } from 'store/reducers/mainUser';

//Monitoring
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';
import { deleteAllOrders, deleteAllOrdersHistory } from 'services/ordersRepository';

export default function Company() {
  const showSnackbar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.mainUser);

  const userDocument = userData?.userDocument;
  const reduxSelectedCompanyID = userData?.userSelectedCompanyID;

  const { t } = useTranslation();

  //Show the container depending on the value | 0 = Null, 1 = Preview, 2 = Create, 3 = Update
  const [value, setValue] = useState('0');

  //======================| Selected Company (used in Preview or Edit) |=======================//
  const [companySelected, setCompanySelected] = useState(null);
  const companyId = companySelected?.id;

  //=====================| CRUD |=======================//
  //-------------| Create Company |-------------//
  async function submitCompany(values) {
    //TODO: this is a temporary solution hardcoed, remove it later
    const features = {
      hasOrders: true
    };

    try {
      //Create company on firestore companies
      //logoUrl and banners are defined as empty slots, because they will be updated after the upload on firebase storage
      const emptySlot = undefined;
      const company = await createCompany(
        emptySlot,
        values.name,
        values.email,
        values.phone,
        values.currencyCode,
        values.defaultLanguage,
        values.languages,
        values.socialLinks,
        values.categories,
        values.mainColor,
        values.secondaryColor,
        features,
        emptySlot
      );
      const companyId = company.id;

      //Add the company id and the name/role to the actual users document
      const companyIDs = userDocument?.companyIDs || {};
      await addUserCompany(userData.userData?.uid, { ...companyIDs, [companyId]: { name: values.name, role: 'admin' } });

      const userID = userData.userData?.uid;
      await refreshToken(userID);

      //Upload logo on firebase Storage and get the url
      let logoUrl = '';
      if (values.logoUrl) {
        logoUrl = await uploadLogo(companyId, values.logoUrl);
      }

      //Upload banners on firebase Storage and get the urls
      let banners = '';
      if (values.banners) {
        banners = await uploadBanners(companyId, values.banners);
      }

      const uploadData = {
        //Data to upload when creating a company
        logoUrl,
        banners
      };

      //Update the company on firestore with the logoUrl and banners urls
      if (logoUrl || banners) await updateCompany(companyId, uploadData);

      //Update on Redux Store userDocument, user companies list
      dispatch(
        setUserDocument({
          userDocument: {
            ...userDocument,
            companyIDs: { ...companyIDs, [companyId]: { name: values.name, role: 'admin' } }
          }
        })
      );

      // showSnackbar(`Company ${values.name} created`);
      showSnackbar(t('Success.lb_company_created_successfully'), 'success');
      monitoring.breadcrumb('[Company Created]', `Company ${companyId} created successfully`);
      setValue('0');
    } catch (error) {
      monitoring.error('Error creating company', error);
      showSnackbar(t('Error.lb_error_creating_company'), 'error');
      return;
    }
  }

  //-------------| Update Company |-------------//
  async function handleUpdateCompany(values) {
    try {
      //(values) -> has only the fields that were changed
      //upload the images on the fire store if it was changed, and get the new URL
      if (values.logoUrl !== undefined) {
        values.logoUrl = await uploadLogo(companyId, values.logoUrl);
      }
      if (values.banners !== undefined) {
        values.banners = await uploadBanners(companyId, values.banners, companySelected.banners.length || 0);
      }

      //Update the company on firestore (company document)
      await updateCompany(companyId, values);

      if (values.name) {
        //Update company name in all user documents (firestore)
        const id = Object.keys(userDocument.companyIDs).filter((i) => i === companyId);

        updateInAllUsers(id, values.name);
        //update the company name on actual user document(Redux store)
        dispatch(
          setUserDocument({
            userDocument: {
              ...userDocument,
              companyIDs: { ...userDocument.companyIDs, [companyId]: { ...userDocument.companyIDs[companyId], name: values.name } }
            }
          })
        );
      }

      showSnackbar(t('Success.lb_company_updated_successfully'), 'success');
      monitoring.breadcrumb('[Company Updated]', `Company ${companyId} updated successfully`);

      //Fecth to update the list
      await fetchCompanies();
    } catch (error) {
      monitoring.error('Error updating company', error);
      showSnackbar(t('Error.lb_error_updating_company'), 'error');
      return;
    }

    //-----------------| Update the actual company selected |-----------------//

    // Filter the keys of the object
    const keys = Object.keys(values).filter((key) => values[key]);

    // Create a new object with only the properties that have non-null values
    const changedValuesObject = keys.reduce((accumulator, key) => {
      accumulator[key] = values[key];
      return accumulator;
    }, {});

    // Update the actual company selected
    setCompanySelected({ ...companySelected, ...changedValuesObject });
    //------------------------------------------------------------------------//

    setValue('1');
  }

  //-------------| Delete Company |-------------//
  async function handleDeleteCompany(company) {
    const id = company.id;
    // const name = item.name;
    try {
      //Delete company from firestore companies
      await deleteCompany(id);

      //Delete the storage(firebase) of the company
      await deleteCompanyStorage(id);

      //Delete all menu items from the company
      await deleteAllItems(id);

      //Delete company from all users documents (Firestore)
      await deleteInAllUsers(id);

      //TODO: Delete orders/orders_history from the company
      await deleteAllOrders(id);
      await deleteAllOrdersHistory(id);

      //Delete company from actual user document(Redux store)
      const filteredCompanyIDs = Object.fromEntries(Object.entries(userDocument.companyIDs).filter(([key]) => key !== id));
      dispatch(setUserDocument({ userDocument: { ...userDocument, companyIDs: filteredCompanyIDs } }));

      //Check if the deleted company was the selected company
      if (reduxSelectedCompanyID === id) {
        dispatch(setUserSelectedCompanyID({ userSelectedCompanyID: null }));
        setCompanySelected(null);
        setValue('0');
      }

      showSnackbar(t('Success.lb_company_deleted_successfully'), 'success');
      monitoring.breadcrumb('[Company Deleted]', `Company ${id} deleted successfully`);
      setValue('0');
    } catch (error) {
      monitoring.error('Error deleting company', error);
      showSnackbar(t('Error.lb_error_deleting_company'), 'error');
      return;
    }
  }

  //======================| Get All My Companies Data By Array of IDs when created or deleted |======================//
  const [myCompaniesList, setMyCompaniesList] = useState([]);

  const myCompaniesIDs = useMemo(() => Object.keys(userDocument?.companyIDs || {})?.map((i) => i), [userDocument?.companyIDs]);

  const fetchCompanies = async () => {
    fetchCompaniesByIds(myCompaniesIDs)
      .then((secondData) => {
        setMyCompaniesList(secondData);
      })
      .catch((error) => {
        monitoring.error('Error fetching companies by IDs', error);
        showSnackbar(t('Error.lb_error_fetching_companies'), 'error');
        return;
      });
  };

  useEffect(() => {
    if (myCompaniesIDs?.length > 0) {
      fetchCompanies();
    } else {
      setMyCompaniesList([]);
    }
    //eslint-disable-next-line
  }, [myCompaniesIDs]);

  //======================| CompaniesList Props |======================//
  const CompaniesListProps = {
    setCompanySelected,
    handleDeleteCompany,
    setValue,
    myCompaniesList
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ display: 'flex', width: '100%', fontFamily: 'Open Sans' }} columns={{ xs: 6, sm: 10, md: 12 }}>
        <Grid item xs={12} md={5}>
          <CompaniesList CompaniesListProps={CompaniesListProps} />
        </Grid>

        <Grid item xs={12} md={7}>
          {(() => {
            switch (value) {
              case '1':
                return companySelected ? (
                  <Preview companySelected={companySelected} setValue={setValue} />
                ) : (
                  <Item>
                    <Grid>
                      <CircularProgress />
                    </Grid>
                  </Item>
                );
              case '2':
                return (
                  <FormComponent
                    key="create"
                    initialValues={{
                      name: '',
                      email: '',
                      phone: '',
                      socialLinks: {},
                      categories: [],
                      mainColor: '#1890ff',
                      secondaryColor: '#faad14',
                      banners: [],
                      languages: [],
                      defaultLanguage: '',
                      currencyCode: null,
                      logoUrl: ''
                    }}
                    action={submitCompany}
                    title={t('Company.lb_create_company')}
                    showSnackbar={showSnackbar}
                    setValue={setValue}
                    userDocument={userDocument}
                    companySelected={companySelected}
                  />
                );
              case '3':
                return companySelected ? (
                  <FormComponent
                    key={companySelected.id}
                    initialValues={{
                      name: companySelected.name,
                      email: companySelected.email,
                      phone: companySelected.phone,
                      socialLinks: companySelected.socialLinks,
                      categories: companySelected.categories || [],
                      mainColor: companySelected.mainColor,
                      secondaryColor: companySelected.secondaryColor,
                      banners: companySelected.banners,
                      languages: companySelected.languages,
                      defaultLanguage: companySelected.defaultLanguage,
                      currencyCode: companySelected.currencyCode,
                      logoUrl: companySelected.logoUrl
                    }}
                    action={handleUpdateCompany}
                    title={t('Company.lb_update_company')}
                    showSnackbar={showSnackbar}
                    setValue={setValue}
                    userDocument={userDocument}
                    companySelected={companySelected}
                  />
                ) : (
                  <Item>
                    <Grid>
                      <CircularProgress />
                    </Grid>
                  </Item>
                );
              default:
                return null;
            }
          })()}
        </Grid>
      </Grid>
    </Box>
  );
}
