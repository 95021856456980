import PropTypes from 'prop-types';

import { Grid, Button, CircularProgress } from '@mui/material';
import color from 'themes/colors';
import { useTranslation } from 'react-i18next';

//Form Components
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { isEqual } from 'lodash';

//Inputs
import DoubleInputList from '../inputs/DoubleInputList';

//EA Design System
import SelectInput from 'components/ea-design-system/SelectInput';
import LogoInput from 'components/ea-design-system/LogoInput';
import TextInput from 'components/ea-design-system/TextInput';
import MainTitle from 'components/ea-design-system/MainTitle';

//Utils
import { findLanguageName } from 'utils/languageFinder';

//languages
import languagesFile from 'assets/languages.json';
const findLanguageCode = (languageName) => languagesFile.find((l) => l.name === languageName.replace(/ \(Default language\)$/, '')).code;

const validationSchema = Yup.object().shape({
  category: Yup.object().required('Category is Required'),
  title: Yup.object()
    .test('title', 'You cannot create a menu item with a blank name', (value) => Object.values(value).some((v) => v))
    .required('You cannot create a menu item with a blank name'),

  portions: Yup.array()
    .test('portions', 'At least one size is required', (value) => value.length > 0)
    .required('At least one size is required')
});

export default function ItemsForm({
  setValue,
  title,
  initialValues,
  action,
  languages,
  categories,
  defaultLanguage,
  currencyCode,
  showSnackbar
}) {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        padding: '24px 24px',
        width: '100%',
        maxWidth: '100%',
        bgcolor: color.backgroundColor,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px',
        textAlign: 'left',

        '& .MuiTypography-root': { p: 1 },
        '& .MuiTextField-root': { mb: 2 },
        '& .MuiButton-root': { m: 'auto', mt: 2, mb: 2 },
        '& .MuiGrid-root': { justifyContent: 'space-evenly' }
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false} //false -> only validate when submit
        onSubmit={(values) => {
          const changedValues = Object.keys(values).reduce((result, key) => {
            if (!isEqual(values[key], initialValues[key])) {
              result[key] = values[key];
            }
            return result;
          }, {});

          //Trim all the title values
          if (changedValues.title) {
            changedValues.title = Object.entries(changedValues.title).reduce((acc, [key, value]) => {
              return { ...acc, [key]: value.trim() };
            }, {});
          }
          //Trim all the ingredients values
          if (changedValues.ingredients) {
            changedValues.ingredients = Object.entries(changedValues.ingredients).reduce((acc, [key, value]) => {
              return { ...acc, [key]: value.trim() };
            }, {});
          }

          //If the category is not null, get the ID instead of the object
          if (changedValues.category) {
            changedValues.category = changedValues.category.id;
          }

          action(changedValues);
        }}
      >
        {({ isSubmitting, values, touched, errors, setFieldValue }) => (
          <Form
            noValidate
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <MainTitle
              style={{
                color: color.fontColor,
                bgcolor: color.backgroundColor
              }}
            >
              {title}
            </MainTitle>
            {/* ========================| Language Select |========================*/}

            <div style={{ padding: '16px 0px 8px 0px' }}>
              <Field
                disableClearable={true}
                name="language"
                component={SelectInput}
                options={languages.map((i) =>
                  i === defaultLanguage ? `${findLanguageName(i)} (${t('MenuItems.lb_default_language')})` : findLanguageName(i)
                )}
                placeholder="Languages..."
                label={`${t('MenuItems.lb_select_language')}`}
                value={
                  defaultLanguage === values.language
                    ? `${findLanguageName(values.language)} (${t('MenuItems.lb_default_language')})`
                    : findLanguageName(values.language)
                }
                onChange={(event, newValue) => {
                  setFieldValue('language', newValue ? findLanguageCode(newValue) : '');
                }}
                error={touched.language && Boolean(errors.language)}
                helperText={touched.language && errors.language}
              />
            </div>

            {/* ========================| Category Select |========================*/}

            <div style={{ padding: '8px 0px' }}>
              <Field
                name="category"
                placeholder="Categories..."
                label={`${t('MenuItems.lb_select_category')}:*`}
                component={SelectInput}
                options={categories}
                value={values.category}
                onChange={(event, newValue) => setFieldValue('category', newValue)}
                //----------
                getOptionLabel={(option) => option?.translation[defaultLanguage]}
                //Note: Java script cant compare objects, so we need to compare the id(values is an object and option is an array of objects)
                //Note: Now based on the "value" javascript will know wich "option" is selected
                isOptionEqualToValue={(option, value) => option.id === value.id}
                //----------
                error={touched.category && Boolean(errors.category)}
                helperText={touched.category && errors.category}
              />
            </div>

            {/* ========================| Image input |========================*/}
            <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
              {t('MenuItems.lb_upload_file')}
            </div>
            <Field
              component={LogoInput}
              imageValidation={{
                maxSize: 1 * 1024 * 1024,
                allowedFormats: ['image/jpg', 'image/jpeg', 'image/png'],
                aspectRatio: 4 / 3,
                aspectRatioSecondary: 3 / 4,
                aspectRatioTolerance: 0.6
              }}
              onError={(errorMessage) => showSnackbar(errorMessage, 'error')}
              value={values.imageUrl}
              onSetField={(file) => setFieldValue('imageUrl', file)}
              title={t('Company.lb_dnd_files')}
              textButton={t('Company.lb_browse')}
            />
            <div style={{ color: color.fontColorSecondary, padding: '8px 0px 12px 0px' }}>
              File size limit: 1MB; Format: .PNG or .JPG; Aspect Ratio between 4:3 to 3:4;
            </div>

            {/* ========================| Name input |========================*/}
            <Field
              component={TextInput}
              name="title"
              label={`${t('MenuItems.lb_name')}:*`}
              placeholder="Enter Menu Item Name"
              value={values.title[values.language] || ''}
              onChange={(e) => {
                setFieldValue('title', { ...values.title, [values.language]: e.target.value });
              }}
              error={touched.title && Boolean(errors.title)}
              helperText={touched.title && errors.title}
            />

            {/* ========================| Description/Ingredients input |========================*/}
            <Field
              component={TextInput}
              name="ingredients"
              label={`${t('MenuItems.lb_description_ingredients')}:*`}
              placeholder="Enter the menu item food ingredients."
              value={values.ingredients[values.language] || ''}
              onChange={(e) => {
                setFieldValue('ingredients', { ...values.ingredients, [values.language]: e.target.value });
              }}
              error={touched.ingredients && Boolean(errors.ingredients)}
              helperText={touched.ingredients && errors.ingredients}
              multiline={true}
              rows={4}
            />
            {/* ========================| Size options input |========================*/}

            <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
              {t('MenuItems.lb_size_options')}
            </div>
            <Field
              name="portions"
              component={DoubleInputList}
              label={`${t('MenuItems.lb_size_name')}:*`}
              labelSecondary={`${t('MenuItems.lb_price')}:*`}
              placeholder="Ex: Small, Medium, Large, etc"
              placeholderSecondary="Entre Price for Size"
              currencyCode={currencyCode}
              languages={languages}
              defaultLanguage={defaultLanguage}
            />

            {/* ========================| Extras options input |========================*/}

            <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
              {t('MenuItems.lb_available_extras')}
            </div>
            <Field
              name="extras"
              component={DoubleInputList}
              label={t('MenuItems.lb_extra_name')}
              labelSecondary={t('MenuItems.lb_price')}
              placeholder="Ex: Pickels, Mashrooms, Other Ingredients"
              placeholderSecondary="Entre Price for Extra"
              currencyCode={currencyCode}
              languages={languages}
              defaultLanguage={defaultLanguage}
            />

            {isSubmitting ? (
              <Grid container justifyContent="center">
                <CircularProgress sx={{ m: 'auto', mb: 3, mt: 3 }} />
              </Grid>
            ) : (
              <div style={{ display: 'flex', gap: '12px' }}>
                <Button
                  type="button"
                  onClick={() => setValue('0')}
                  sx={{
                    m: 'auto',
                    mb: 3,
                    mt: 3,
                    width: '100%',
                    fontWeight: '600',
                    color: color.fontColor,
                    backgroundColor: '#F2E8E8',
                    padding: '8px 32px',
                    '&:hover': { backgroundColor: '#E9E0E0' }
                  }}
                >
                  {t('System.lb_cancel')}
                </Button>
                <Button
                  type="submit"
                  sx={{ m: 'auto', mb: 3, mt: 3, width: '100%', fontWeight: '600', padding: '8px 32px' }}
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {title}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

ItemsForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired
};
