import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './ErrorBoundary';
import { version } from 'react';

import 'assets/third-party/apex-chart.css'; //TODO: Check if this is needed
import reportWebVitals from './reportWebVitals'; //TODO: Check if this is needed

// third-party
import 'simplebar/src/simplebar.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';

//Redux
import { Provider } from 'react-redux';
import { store } from 'store';

//Components
import App from './App';
import CookieConsent from 'components/CookieConset/index';

//Sentry
import initializeSentry from './SentryConfig';
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';
import SentryRemoteLogger from './SentryConfig/sentry_remote_logger';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

// Initialize Firebase and Sentry
initializeSentry();
const sentryLogger = new SentryRemoteLogger();
monitoring.setupLoggers([sentryLogger]);

// TODO: Move to Sentry Information
if (process.env.REACT_APP_IS_LOCALHOST) {
  console.log(`React Version: ${version}`);
}

const container = document.getElementById('root');

//TODO: Service Worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);
//       })
//       .catch((error) => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <App />
          <CookieConsent />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
