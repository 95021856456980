import { IconButton } from '@mui/material';
import { useState } from 'react';

//Assets
import ASSETS from 'enums/assets';

export default function FullScreenButton() {
  const [fullScreen, setFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  };

  return (
    <IconButton size="large" onClick={handleFullScreen}>
      <img src={fullScreen ? ASSETS.ICONS.ACTIONS.FULLSCREEN_EXIT : ASSETS.ICONS.ACTIONS.FULLSCREEN} alt="Full screen" />
    </IconButton>
  );
}
