import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//Material-UI / Color
import { Grid, Button, CircularProgress } from '@mui/material';
import color from 'themes/colors';

//Components Inputs
import CategoriesInput from './inputs/CategoriesInput/index';
import BannersInputSlider from './inputs/BannersInputSlider';
import SocialLinksInput from './inputs/SocialLinksInput';
import LanguageInput from './inputs/LanguageInput/index';
import CurrencyInput from './inputs/CurrencyInput/index';

//EA Design System
import LogoInput from 'components/ea-design-system/LogoInput';
import ColorInput from 'components/ea-design-system/ColorInput';
import TextInput from 'components/ea-design-system/TextInput';
import MainTitle from 'components/ea-design-system/MainTitle';

//Form Components
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { isEqual } from 'lodash';
import { getImageForPath } from 'utils/imageUtils';

//Check for repeated company name
const checkForRepeatedCompanyName = (name, userDocument, companySelected) => {
  const companyIDs = userDocument.companyIDs;
  return Object.entries(companyIDs).some((company) => company[1].name === name && company[0] !== companySelected?.id);
};

const validationSchema = (userDocument, companySelected) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Company Name is Required')
      .test('unique-company-name', 'You already have a company with that name', function (value) {
        return !checkForRepeatedCompanyName(value, userDocument, companySelected);
      }),
    email: Yup.string('Enter your email').trim().email('Invalid email address').required('Email is Required'),
    phone: Yup.string()
      .trim()
      .matches(/^(\+)?\d+$/, 'Invalid phone number')
      .required('Phone number is Required'),
    languages: Yup.array().min(1, 'At least one language is required'),
    currencyCode: Yup.string().required('Currency is Required'),
    categories: Yup.array().min(1, 'At least one category is required')
  });

export default function FormComponent({ initialValues, action, title, setValue, showSnackbar, userDocument, companySelected }) {
  const { t } = useTranslation();

  const imageValidation = {
    maxSize: 1 * 1024 * 1024,
    allowedFormats: ['image/jpg', 'image/jpeg', 'image/png'],
    aspectRatio: 1 / 1,
    aspectRatioTolerance: 0.2
  };

  return (
    <Grid
      sx={{
        padding: '24px 24px',
        width: '100%',
        maxWidth: '100%',
        bgcolor: color.backgroundColor,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px',
        textAlign: 'left',

        '& .MuiTypography-root': { p: 1 },
        '& .MuiTextField-root': { mb: 2 },
        '& .MuiButton-root': { m: 'auto', mt: 2, mb: 2 },
        '& .MuiGrid-root': { justifyContent: 'space-evenly' }
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(userDocument, companySelected)}
        validateOnChange={false} // only validate when the form is submitted
        onSubmit={(values) => {
          //Trim all the values, this will remove the white spaces from the beginning and the end of the strings
          const trimmedValues = Object.keys(values).reduce((result, key) => {
            result[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
            return result;
          }, {});

          //This will compare the initial values with the new values and send only the changed ones
          const changedValues = Object.keys(trimmedValues).reduce((result, key) => {
            if (!isEqual(trimmedValues[key], initialValues[key])) {
              result[key] = trimmedValues[key];
            }
            return result;
          }, {});

          action(changedValues);
        }}
      >
        {({ isSubmitting, values, touched, errors, setFieldValue }) => {
          return (
            <Form
              noValidate
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            >
              <MainTitle style={{ color: color.fontColor, bgcolor: color.backgroundColor }}>{title}</MainTitle>

              {/* ========================| Logo input |========================*/}
              <div style={{ fontSize: '18px', fontWeight: 700, padding: '24px 0px 8px 0px', color: color.fontColor }}>
                {t('Company.lb_company_logo')}
              </div>
              <Field
                component={LogoInput}
                imageValidation={imageValidation}
                onError={(errorMessage) => showSnackbar(errorMessage, 'error')}
                value={getImageForPath(values.logoUrl, '448', '448', 'webp')}
                onSetField={(file) => setFieldValue('logoUrl', file)}
                title={t('Company.lb_dnd_files')}
                textButton={t('Company.lb_browse')}
              />
              <div style={{ color: color.fontColorSecondary, padding: '8px 0px 12px 0px' }}>
                File size limit: 1MB; Format: .PNG or .JPG; Aspect Ratio: 1:1;
              </div>

              {/* =======================| Colors inputs |=======================*/}
              <div
                style={{
                  display: 'flex',
                  gap: '24px',
                  padding: '8px 0 24px 0px',
                  margin: '8px 0 24px 0px',
                  borderBottom: `1px solid ${color.dividerColor}`
                }}
              >
                <Field
                  title={t('Company.lb_primary_color')}
                  component={ColorInput}
                  value={values.mainColor}
                  onSetField={(value) => setFieldValue('mainColor', value)}
                />

                <Field
                  title={t('Company.lb_secondary_color')}
                  component={ColorInput}
                  value={values.secondaryColor}
                  onSetField={(value) => setFieldValue('secondaryColor', value)}
                />
              </div>

              <Grid>
                {/* ===================| Name/Email/Phone input |===================*/}
                <Grid container sx={{ borderBottom: 2, borderColor: 'divider', padding: '8px 0 24px 0px', margin: '8px 0 24px 0px' }}>
                  <Field
                    component={TextInput}
                    id="name"
                    name="name"
                    label={`${t('Company.lb_company_name')}:*`}
                    placeholder="Enter Company Name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <Field
                    component={TextInput}
                    id="email"
                    name="email"
                    label={`${t('System.lb_email')}:*`}
                    placeholder="Enter Your Email"
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <Field
                    component={TextInput}
                    id="phone"
                    name="phone"
                    label={`${t('System.lb_phone')}:*`}
                    placeholder="Enter Your Phone Number"
                    value={values.phone}
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>

                {/* =====================| Social Links inputs |=====================*/}
                <div style={{ padding: '8px 0px', rowGap: '12px', display: 'grid' }}>
                  <div style={{ padding: '16px 0px 8px 0px', fontSize: '18px', fontWeight: 700, color: color.fontColor }}>
                    {t('System.lb_add_social_link')}
                  </div>
                  <Grid>
                    <Field name="socialLinks" component={SocialLinksInput} socialLinks={initialValues.socialLinks} />
                  </Grid>
                </div>
                {/* =====================| Available Languages |=====================*/}
                <div style={{ borderRight: 2, borderColor: 'divider', rowGap: '12px', display: 'grid', padding: '8px 0px' }}>
                  <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
                    {t('Company.lb_add_available_languages')}
                  </div>
                  <Field name="languages" component={LanguageInput} value={initialValues.languages} />
                </div>
                {/* =====================| Available Currency |=====================*/}
                <div style={{ rowGap: '12px', display: 'grid', padding: '8px 0px', borderBottom: `1px solid ${color.dividerColor}` }}>
                  <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
                    {t('Company.lb_choice_currency')}
                  </div>
                  <Field name="currencyCode" component={CurrencyInput} value={initialValues.currencyCode} />
                </div>
                {/* =======================| Menu Categories inputs |=======================*/}
                <div style={{ borderRight: 2, borderColor: 'divider', rowGap: '12px', display: 'grid', padding: '8px 0px' }}>
                  <div style={{ fontSize: '18px', fontWeight: 700, padding: '16px 0px 8px 0px', color: color.fontColor }}>
                    {t('Company.lb_menu_Information')}
                  </div>
                  <Field
                    disabled={values.languages.length === 0}
                    disabledWarning={t('Company.lb_select_at_least_one_language')}
                    //----
                    name="categories"
                    component={CategoriesInput}
                    value={initialValues.categories}
                    languages={values.languages}
                    values={values}
                  />
                </div>
                {/* =======================| Banners inputs |=======================*/}
                <Grid>
                  <Field
                    name="banners"
                    component={BannersInputSlider}
                    banners={initialValues.banners}
                    onError={(errorMessage) => showSnackbar(errorMessage, 'error')}
                  />
                </Grid>
              </Grid>

              {isSubmitting ? (
                <Grid container justifyContent="center">
                  <CircularProgress sx={{ m: 'auto', mb: 3, mt: 3 }} />
                </Grid>
              ) : (
                <div style={{ display: 'flex', gap: '12px' }}>
                  <Button
                    type="button"
                    onClick={() => setValue('0')}
                    sx={{
                      m: 'auto',
                      mb: 3,
                      mt: 3,
                      width: '100%',
                      fontWeight: '600',
                      color: color.fontColor,
                      backgroundColor: '#F2E8E8',
                      padding: '8px 32px',
                      '&:hover': { backgroundColor: '#E9E0E0' }
                    }}
                  >
                    {t('System.lb_cancel')}
                  </Button>
                  <Button
                    type="submit"
                    sx={{ m: 'auto', mb: 3, mt: 3, width: '100%', fontWeight: '600', padding: '8px 32px' }}
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {title}
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

FormComponent.propTypes = {
  action: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  userDocument: PropTypes.object.isRequired,
  companySelected: PropTypes.object
};
