import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

//Material UI / Styles
import { Button } from '@mui/material';
import styles from './Preview.module.css';

//Utils
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { findLanguageName } from 'utils/languageFinder';

//Components
import { Item } from 'components/StyledComponents';
import ImageSlider from 'components/ea-design-system/ImageSlider';

//Assets
import ASSETS from 'enums/assets';
import { getImageForPath } from 'utils/imageUtils';

export default function Preview({ companySelected, setValue }) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(companySelected.defaultLanguage);

  const icons = {
    facebook: ASSETS.ICONS.SOCIAL.FACEBOOK,
    youtube: ASSETS.ICONS.SOCIAL.YOUTUBE,
    twitter: ASSETS.ICONS.SOCIAL.TWITTER,
    instagram: ASSETS.ICONS.SOCIAL.INSTAGRAM,
    tiktok: ASSETS.ICONS.SOCIAL.TIKTOK,
    linkedin: ASSETS.ICONS.SOCIAL.LINKEDIN,
    phone: ASSETS.ICONS.INFO.PHONE,
    email: ASSETS.ICONS.INFO.EMAIL
  };

  const socialLinks = companySelected?.socialLinks || {};
  const contacts = { phone: companySelected?.phone, email: companySelected?.email };

  return (
    <Item className={styles.previewContainer}>
      <div className={styles.headerContainer}>
        <img
          className={styles.logo}
          alt="logo"
          src={getImageForPath(companySelected?.logoUrl, 128, 128, 'webp') || ASSETS.IMAGES.PLACEHOLDERS.PLACEHOLDER_1_BY_1}
        />
        <div>
          <div className={styles.name}>{companySelected?.name}</div>
          <div className={styles.email}>{companySelected?.email}</div>
        </div>
      </div>
      <ImageSlider images={companySelected?.banners?.map((banner) => getImageForPath(banner, 900, 504, 'webp'))} />
      {/* ==================| Categories |================== */}
      <div className={styles.boxTagsContainer}>
        <div className={styles.availableMenuTitle}>{t('Company.lb_available_menu_categories')}</div>
        <div className={styles.flex}>
          {companySelected?.categories?.map((category) => (
            <div key={category.id} className={styles.tagBox}>
              <img alt="ic_restaurante" src={ASSETS.ICONS.INFO.RESTAURANT} />
              <div>
                {capitalizeFirstLetter(category.translation[selectedLanguage] || category.translation[companySelected?.defaultLanguage])}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ==================| Languages |================== */}
      <div className={styles.boxTagsContainer}>
        <div className={styles.availableMenuTitle}>{t('Company.lb_available_menu_languages')}</div>
        <div className={styles.flex}>
          {companySelected?.languages?.map((lang) => (
            <button key={lang} className={styles.tagBox} onClick={() => setSelectedLanguage(lang)}>
              <img alt="ic_global" src={ASSETS.ICONS.INFO.GLOBAL} />
              <div>{findLanguageName(lang)}</div>
            </button>
          ))}
        </div>
      </div>
      {/* ==================| Contacts & Social |================== */}
      <div className={styles.contactsSocialContainer}>
        {Object.entries(contacts).length !== 0 && (
          <div className={styles.contacts}>
            <div className={styles.contactsSocialTitle}>{t('Company.lb_contacts')}</div>
            {contacts &&
              Object.entries(contacts).map(([key, value]) => (
                <div className={styles.listItem} key={key}>
                  <img alt="icon" src={icons[key]} />
                  {value}
                </div>
              ))}
          </div>
        )}
        {Object.entries(socialLinks).length !== 0 && (
          <div className={styles.social}>
            <div className={styles.contactsSocialTitle}>{t('Company.lb_social')}</div>
            {companySelected &&
              Object.entries(socialLinks).map(
                ([key, value]) =>
                  value && (
                    <div className={styles.listItem} key={key}>
                      <img alt="icon" src={icons[key]} />
                      <a style={{ color: '#000' }} href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                      </a>
                    </div>
                  )
              )}
          </div>
        )}
      </div>
      <Button className={styles.updateButton} variant="contained" onClick={() => setValue('3')}>
        {t('System.lb_edit')}
      </Button>
    </Item>
  );
}

Preview.propTypes = {
  companySelected: PropTypes.object,
  setValue: PropTypes.func
};
