import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

//Redux
import { useSelector } from 'react-redux';

// styles / material ui components
import { Button, Alert } from '@mui/material';
import color from 'themes/colors';
import styles from './QRCodeCreate.module.css';

//Components
import SelectCompany from 'components/SelectCompany';
//EA Design System
import TextInput from 'components/ea-design-system/TextInput';
import MainTitle from 'components/ea-design-system/MainTitle';

//Services
import { uploadQRCode } from 'services/qrCodeRepository';

//Monitoring
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';

export default function QRCodeCreate({ selectedCompany, handleFetchCompanyQRCodes, companyQRCodesKeys, showSnackbar }) {
  const { t } = useTranslation();

  //===========| Redux |===========//
  const userDocument = useSelector((state) => state.mainUser.userDocument);
  const companys = userDocument?.companyIDs ? Object.keys(userDocument?.companyIDs) : [];
  //===============================//

  const [QRCodeName, setQRCodeName] = useState('');
  const [QRCodeErrorInput, setQRCodeErrorInput] = useState('');

  //========| Validation schema |========//
  const schema = Yup.object().shape({
    QRCodeName: Yup.string()
      .trim()
      .required('A name is required.')
      .matches(/^[a-zA-Z0-9]/, 'The name is invalid.')
  });
  //============ POST QR Codes ============//
  const handleUploadQRCode = async () => {
    try {
      await schema.validate({ QRCodeName });

      if (companyQRCodesKeys.includes(QRCodeName)) {
        setQRCodeErrorInput('The name already exists.');
        return;
      }

      const companyID = selectedCompany;
      const tabelID = uuidv4(); //generate a rundom id
      const url = 'https://swift-menu.com/company/' + companyID + '?table=' + tabelID;

      await uploadQRCode(url, QRCodeName, companyID);
      setQRCodeName('');
      setQRCodeErrorInput('');
      showSnackbar(t('Success.lb_qrcode_created'), 'success');
      monitoring.breadcrumb('[QRCode Created]', `QR code "${QRCodeName}" created successfully`);
      //Make a new fetch to update the list of qr codes
      await handleFetchCompanyQRCodes(companyID);
    } catch (error) {
      showSnackbar(t('Error.lb_error_uploading_qr_code'), 'error');
      monitoring.error(`Error uploading qr code: ${error}`);
    }
  };

  return (
    <div className={styles.containerGap}>
      <MainTitle style={{ color: color.fontColor, borderBottom: `1px solid ${color.dividerColor}`, bgcolor: color.backgroundColor }}>
        {t('QRCode.lb_create_new_qr_code')}
      </MainTitle>

      {(companys?.length === 0 || !companys) && (
        <Alert variant="filled" severity="info" sx={{ background: '#42a5f5', color: '#fff', fontWeight: '600' }}>
          {t('System.lb_not_registered_in_company')}
        </Alert>
      )}

      <SelectCompany />

      {selectedCompany && (
        <>
          <TextInput
            label={`${t('QRCode.lb_qr_code_name')}:*`}
            placeholder="Table 1, Room 2, etc..."
            value={QRCodeName}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleUploadQRCode();
            }}
            onChange={(e) => setQRCodeName(e.target.value)}
            helperText={QRCodeErrorInput}
            error={QRCodeErrorInput ? true : false}
          />

          <div style={{ textAlign: 'right' }}>
            <Button sx={{ mt: '20px', fontWeight: '600' }} variant="contained" onClick={() => handleUploadQRCode()}>
              {t('QRCode.lb_create_new_qr_code')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

QRCodeCreate.propTypes = {
  selectedCompany: PropTypes.string,
  companyQRCodesKeys: PropTypes.array,
  userCompanys: PropTypes.array,
  handleFetchCompanyQRCodes: PropTypes.func,
  showSnackbar: PropTypes.func
};
