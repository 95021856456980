import style from '../DoubleInputList.module.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

//Material-ui / Color
import { Grid, IconButton } from '@mui/material';
import color from 'themes/colors';

//Assets
import ASSETS from 'enums/assets';

//Utils
import FadeItemList from 'utils/RenderEffects/FadeItemList';
import { findLanguageName } from 'utils/languageFinder';

export default function Item({
  item,
  defaultLanguage,
  languages,
  currencyCode,
  handleDelete,
  handleOnChangeTranslation,
  handleOnChangePrice,
  index
}) {
  const { t } = useTranslation();
  return (
    <Grid
      key={item.id}
      sx={{
        maxHeight: '250px',
        overflow: 'scroll',
        padding: '12px 16px',
        bgcolor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
      }}
    >
      <FadeItemList>
        <div>
          <div className={style.MainItemListContainer}>
            <div className={style.ItemList}>
              <div className={style.lang}>{`${findLanguageName(defaultLanguage)} (${defaultLanguage})`}</div>
              <input value={item.translation[defaultLanguage]} onChange={() => handleOnChangeTranslation(item, defaultLanguage)} />
            </div>

            <div style={{ padding: '10px' }}> {`${currencyCode}:`}</div>
            <input
              type="number"
              min={0}
              className={style.priceInput}
              value={item.price}
              onChange={(e) => handleOnChangePrice(item, e.target.value)}
            />

            <div style={{ color: '#8f8f8f', padding: '10px' }}>{t('Company.lb_default_language')}</div>

            <IconButton aria-label="delete" size="medium" onClick={() => handleDelete(item)}>
              <img src={ASSETS.ICONS.ACTIONS.DELETE} alt="delete" />
            </IconButton>
          </div>

          {languages
            .filter((lang) => lang !== defaultLanguage)
            .map((lang) => (
              <div key={lang} className={style.ItemList}>
                <div className={style.lang}>{`${findLanguageName(lang)} (${lang})`}</div>
                <input value={item.translation[lang]} onChange={() => handleOnChangeTranslation(item, lang)} />
              </div>
            ))}
        </div>
      </FadeItemList>
    </Grid>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  currencyCode: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOnChangeTranslation: PropTypes.func.isRequired,
  handleOnChangePrice: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};
