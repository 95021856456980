import PropTypes from 'prop-types';

//Icons / Color
import color from 'themes/colors';

//Hooks / Utils
import { useTranslation } from 'react-i18next';

// Import required modules
import useImageValidator from 'utils/useImageValidator';
import ImageSlider from 'components/ea-design-system/ImageSlider';
import BannerInput from 'components/ea-design-system/BannerInput';
import { getImageForPath } from 'utils/imageUtils';

export default function BannersInput({ form, field, onError }) {
  const { t } = useTranslation();
  const { validateImage } = useImageValidator(1 * 1024 * 1024, ['image/jpg', 'image/jpeg', 'image/png'], 16 / 9, 2 / 1, 0.4);

  const addBanner = async (file) => {
    const { isError, errorMessage } = await validateImage(file);
    if (!isError) {
      form.setFieldValue(field.name, [...field.value, file]);
    } else {
      onError(errorMessage);
    }
  };

  const deleteBanner = (index) => {
    form.setFieldValue(
      field.name,
      field.value.filter((_, ind) => {
        return ind !== index;
      })
    );
  };

  const fieldValue = field.value || [];

  return (
    <>
      <div style={{ padding: '16px 0px 8px 0px', fontSize: '18px', fontStyle: 'normal', fontWeight: 700, color: color.fontColor }}>
        {t('Company.lb_menu_headers')}
      </div>
      {fieldValue.length < 3 && (
        <>
          <BannerInput onAddBanner={addBanner} title={t('Company.lb_upload_banner')} textButton={t('Company.lb_upload_banner')} />

          <div style={{ color: color.fontColorSecondary, padding: '4px 0px 12px 0px' }}>
            File size limit: 1MB; Format: .PNG or .JPG; Aspect Ratio: 16:9; Max 3 Images;
          </div>
        </>
      )}
      {/* =======================| Image Slider |======================= */}

      <ImageSlider images={fieldValue.map((banner) => getImageForPath(banner, 900, 504, 'webp'))} onDelete={deleteBanner} />

      {/* ============================================================== */}
    </>
  );
}

BannersInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired
};
