import PropTypes from 'prop-types';
import style from './CategoriesInput.module.css';

//Material-UI / Icons
import { Grid, TextField, IconButton, FormControl, InputAdornment } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import color from 'themes/colors';

//Hooks / Utils
import FadeItemList from 'utils/RenderEffects/FadeItemList';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { findLanguageName } from 'utils/languageFinder';
import ASSETS from 'enums/assets';

export default function CategoriesInput({ form, field, disabledWarning, disabled, languages, values }) {
  const { t } = useTranslation();

  const [category, setCategory] = useState('');

  const createCategory = () => {
    if (!category) {
      form.setFieldError(field.name, t('Error.lb_field_is_empty'));
      return;
    } else if (field.value.map((item) => item.translation[values.defaultLanguage].toLowerCase()).includes(category.toLowerCase())) {
      form.setFieldError(field.name, t('Error.lb_category_already_exists'));
      return;
    }

    // Add category to catergories array
    const newObj = languages.reduce((acc, lang) => {
      acc[lang] = category.trim();
      return acc;
    }, {});

    form.setFieldValue(field.name, [...field.value, { id: uuidv4(), ['translation']: newObj }]);
    //============================================================//

    setCategory('');
  };

  const deleteCategory = (i) => {
    form.setFieldValue(
      field.name,
      field.value.filter((item) => {
        return item !== i;
      })
    );
  };

  const handleOnChangeTranslation = (item, lang) => {
    const newItem = field.value.find((i) => i.id === item.id);
    //This will update the translation from field.value and reset the field value
    newItem.translation[lang] = event.target.value;
    form.setFieldValue(field.name, field.value);
  };

  //Update the translation object when languages are updated
  useEffect(() => {
    if (languages.length > 0) {
      const newCategories = field.value.map((item) => {
        const newObj = languages.reduce((acc, lang) => {
          acc[lang] = item.translation[lang] || item.translation[values.defaultLanguage];
          return acc;
        }, {});

        return { ...item, translation: newObj };
      });

      form.setFieldValue(field.name, newCategories);
    } else {
      form.setFieldValue(field.name, []);
    }

    //eslint-disable-next-line
  }, [languages]);

  return (
    <Grid>
      <Grid container>
        <Grid item xs sx={{ position: 'relative' }}>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: disabled ? color.fontColorSecondary : color.fontColor }} htmlFor="SocialLink">
              {t('System.lb_category')}:*
            </label>
            <TextField
              disabled={disabled}
              id="category"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              placeholder="Ex: Drinks, Meat, Fish, etc"
              variant="outlined"
              fullWidth
              onKeyDown={(e) => {
                if (e.key === 'Enter') createCategory();
              }}
              error={Boolean(form.errors[field.name])}
              helperText={form.errors[field.name]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={createCategory} edge="end" disabled={disabled}>
                      <PlusOutlined />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
          {disabled && <div className={style.disabledWarning}>{disabledWarning}</div>}
        </Grid>
      </Grid>

      <div style={{ maxHeight: '300px', flexGrow: 1, overflow: 'scroll', px: 1, pt: 1 }}>
        {field.value?.map((i, index) => {
          return (
            <Grid
              key={i.id}
              sx={{
                padding: '16px 12px',
                bgcolor: index % 2 === 0 ? color.listStandard : color.listStandardSecondary
              }}
            >
              <FadeItemList>
                <div>
                  <div className={style.MainItemListContainer}>
                    <div className={style.ItemList}>
                      <div className={style.lang}>{`${findLanguageName(values.defaultLanguage)} (${values.defaultLanguage})`}</div>
                      <input
                        value={i.translation[values.defaultLanguage]}
                        onChange={() => handleOnChangeTranslation(i, values.defaultLanguage)}
                      />
                    </div>
                    <div style={{ color: '#8f8f8f', padding: '10px' }}>{t('Company.lb_default_language')}</div>

                    <IconButton aria-label="add" size="medium" onClick={() => deleteCategory(i)}>
                      <img src={ASSETS.ICONS.ACTIONS.DELETE} alt="delete" />
                    </IconButton>
                  </div>

                  {languages
                    .filter((lang) => lang !== values.defaultLanguage)
                    .map((lang) => (
                      <div key={lang} className={style.ItemList}>
                        <div className={style.lang}>{`${findLanguageName(lang)} (${lang})`}</div>
                        <input value={i.translation[lang] || ''} onChange={() => handleOnChangeTranslation(i, lang)} />
                      </div>
                    ))}
                </div>
              </FadeItemList>
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
}

CategoriesInput.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  disabledWarning: PropTypes.string,
  disabled: PropTypes.bool,
  languages: PropTypes.array,
  values: PropTypes.object
};
