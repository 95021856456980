import { auth } from 'zFirebaseConfigs/firebase';

import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setUserData, clearUserData, clearUserSelectedCompanyID, clearDocumentData, setUserDocument } from 'store/reducers/mainUser';
import { fetchUserById } from 'services/userRepository';

//Monitoring
import { monitoring } from '@Ethical-Algorithm/reactjs-ea-logger-kit';

// eslint-disable-next-line react/prop-types
export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        //Firebase Auth Observable
        onAuthStateChanged(auth, async (user) => {
          if (!user) {
            //TODO: Maybe there is a better way to clean Redux(mainUser) store
            dispatch(clearUserData());
            dispatch(clearUserSelectedCompanyID());
            dispatch(clearDocumentData());
            monitoring.logout();
            navigate('/login');
          } else {
            setIsAuth(true);

            //Set userData in global store(Redux)
            dispatch(
              setUserData({
                userData: {
                  displayName: user.displayName,
                  email: user.email,
                  photoURL: user.photoURL,
                  uid: user.uid
                }
              })
            );

            const userDocument = await fetchUserById(user.uid); //fetchUserById -> fetch user document by user id

            //Set userDocument in Redux
            dispatch(
              setUserDocument({
                userDocument: userDocument
              })
            );

            monitoring.login(true, {
              id: user.uid,
              email: user.email,
              displayName: user.displayName
            });
            navigate('/dashboard');
          }
        });
      } catch (error) {
        monitoring.error('Error fetching user:', error.message);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuth ? children : null;
}
