import { useRef, useState } from 'react';
import PropType from 'prop-types';
import html2canvas from 'html2canvas';

//Assets / icons
import logo from 'assets/images/QRLogo.svg';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';

//Styles / material-ui
import styles from './QRCodePreview.module.css';
import { Button } from '@mui/material';

//EA Design System
import MainTitle from 'components/ea-design-system/MainTitle';
import { useTranslation } from 'react-i18next';

//Component
import QRCodeRef from './components/QRCodeRef/index';

const qrCodeDownloadOptions = ['png', 'jpeg', 'webp'];

export default function QRCodePreview({ selectedQRCode }) {
  const { t } = useTranslation();
  const [downloadOption, setDownloadOption] = useState('png');
  const qrCodeRef = useRef(null);
  const QRCodeURL = selectedQRCode[1];
  const QRCodeName = selectedQRCode[0];

  //=============================| Download System |=============================//
  const downloadQRCode = () => {
    //html2canvas gets the ref of the element and creates an image based on html and css
    html2canvas(qrCodeRef.current).then((canvas) => {
      const imageUrl = canvas.toDataURL(`image/${downloadOption}`);
      const link = document.createElement('a');

      link.download = `${QRCodeName.trim().replace(/\s/g, '_')}.${downloadOption}`;
      link.href = imageUrl;
      link.click();
    });
  };

  //=============================| Print System |=============================//
  const printStyle = `
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    max-width: 800px;
    max-height: 800px;
  `;

  const printQRCode = () => {
    //html2canvas gets the ref of the element and creates an image based on html and css
    html2canvas(qrCodeRef.current).then((canvas) => {
      const printWindow = window.open();
      printWindow.document.write(
        `<html><head><title>${QRCodeName}</title><div>${QRCodeName}<img style="${printStyle}" src="` +
          canvas.toDataURL() +
          '" /></div></html>'
      );
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
      };
    });
  };

  return (
    <div className={styles.previewCode}>
      <MainTitle>{t('QRCode.lb_preview_qr_code')}</MainTitle>
      <div className={styles.qrCodeTitle}>{QRCodeName}</div>
      <QRCodeRef qrCodeRef={qrCodeRef} QRCodeURL={QRCodeURL} logo={logo} />

      <div className={styles.QRNote}>
        <div style={{ fontWeight: 'bold' }}>Note:</div> {t('QRCode.lb_note')}
      </div>

      <div className={styles.QRCodeActions}>
        <select className={styles.downloadOption} value={downloadOption} onChange={(e) => setDownloadOption(e.target.value)}>
          {qrCodeDownloadOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <Button
          className={styles.actionButtonDownload}
          variant="contained"
          startIcon={<DownloadOutlined />}
          onClick={() => downloadQRCode()}
        >
          {t('System.lb_download')}
        </Button>

        <Button className={styles.actionButtonPrint} variant="outlined" startIcon={<PrinterOutlined />} onClick={() => printQRCode()}>
          {t('System.lb_print')}
        </Button>
      </div>
    </div>
  );
}

QRCodePreview.propTypes = {
  selectedQRCode: PropType.array
};
