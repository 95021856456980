import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';
import { configureAppCheckDebugToken, firebaseConfig, reCaptchaKey } from './appConfig';

//====================| Init Firebase Config |====================//
configureAppCheckDebugToken();

const firebaseApp = initializeApp(firebaseConfig);

// process.env.REACT_APP_FIREBASE_FIRESTORE_ID
//   ? getFirestore(firebaseApp, process.env.REACT_APP_FIREBASE_FIRESTORE_ID)

// Access data offline
// enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code == 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code == 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });

//App Check
export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(reCaptchaKey),
  isTokenAutoRefreshEnabled: true
});

//=====================| Refresh Token |=====================//
export const refreshToken = async (userID) => {
  try {
    const appCheckToken = await getToken(appCheck, true); //This will get a new App Check token
    await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}/updateClaims`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken.token
      },
      body: JSON.stringify({
        userId: userID
      })
    });
    const user = await getAuth().currentUser;
    await user.getIdToken(true); //forceRefresh = true
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const auth = getAuth(firebaseApp);
// Get Firestore instance and connect
export const db = initializeFirestore(firebaseApp, {
  cacheSizeBytes: 1048576, // Optional: customize cache size
  cache: 'persistent' // 'persistent' enables IndexedDb caching, 'memory' disables it
});
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const performance = getPerformance(firebaseApp);

// Initialize Firebase
//=====================| Emulators |=====================//
if (process.env.REACT_APP_IS_LOCALHOST === 'true') {
  const functions = getFunctions(firebaseApp);

  // Connect to the Firestore Emulator
  if (process.env.REACT_APP_FIRESTORE_EMULATOR_PORT) {
    connectFirestoreEmulator(db, 'localhost', process.env.REACT_APP_FIRESTORE_EMULATOR_PORT); //--------------------> emulators
  }

  // Connect to the Storage Emulator
  if (process.env.REACT_APP_STORAGE_EMULATOR_PORT) {
    connectStorageEmulator(storage, 'localhost', process.env.REACT_APP_STORAGE_EMULATOR_PORT); //--------------------> emulators
  }

  // Connect to the Authentication Emulator
  if (process.env.REACT_APP_AUTH_EMULATOR_PORT) {
    connectAuthEmulator(auth, `http://localhost:${process.env.REACT_APP_AUTH_EMULATOR_PORT}`); //--------------------> emulators
  }

  // Connect to the Functions Emulator
  if (process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT) {
    connectFunctionsEmulator(functions, 'localhost', process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT); //--------------------> emulators
  }
}
