import { Snackbar, IconButton, Alert } from '@mui/material';
import { useState } from 'react';
import { SnackbarContext } from './SnackbarContext';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';

//Assets
import ASSETS from 'enums/assets';

export default function SnackbarProvider({ children }) {
  const [snackConfig, setSnackConfig] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: '',
    transition: Slide,
    type: 'success', // success is defined as default but you can pass the type as a prop (success, error, warning, info )
    time: 3000 // 3 seconds
  });

  const alertColors = {
    '': '#FFFFFF',
    success: '#064C3D',
    error: '#E57F09',
    warning: '#FFC107',
    info: '#2196F3'
  };

  const { vertical, horizontal, transition, open, message, time, type } = snackConfig;

  const showSnackbar = (message, type) => {
    setSnackConfig({ ...snackConfig, open: true, message, type });
  };

  const closeSnackbar = () => {
    setSnackConfig({ ...snackConfig, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        onClose={closeSnackbar}
        open={open}
        key={vertical + horizontal}
        TransitionComponent={transition}
        autoHideDuration={time}
        action={
          <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={closeSnackbar}>
            <img alt="close" src={ASSETS.ICONS.ACTIONS.CLOSE} />
          </IconButton>
        }
      >
        <Alert onClose={closeSnackbar} severity={type} variant="filled" sx={{ width: '100%', backgroundColor: alertColors[type] }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node
};
