import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

//Material-ui / Color
import { Grid, TextField, IconButton, InputAdornment, FormControl } from '@mui/material';
import color from 'themes/colors';

//Icons
import { PlusOutlined } from '@ant-design/icons';

//Component
import Item from './components/Item';
export default function DoubleInputList({
  form,
  field,
  labelSecondary,
  label,
  placeholderSecondary,
  placeholder,
  currencyCode,
  languages,
  defaultLanguage
}) {
  const { t } = useTranslation();
  const [value1, setValue1] = useState(''); //Field 1
  const [value2, setValue2] = useState(''); //Field 2

  //==========================| On Create Item |==========================//
  const createItem = () => {
    let errorMessage = '';
    if (!value1) {
      errorMessage = t('Error.lb_please_fill_the_name_field');
    } else if (isNaN(value2)) {
      errorMessage = t('Error.lb_please_enter_valid_price');
    }
    if (errorMessage) {
      form.setFieldError(field.name, errorMessage);
      return;
    }

    const newObj = languages.reduce((acc, lang) => {
      acc[lang] = value1.trim();
      return acc;
    }, {});

    form.setFieldValue(field.name, [...field.value, { id: uuidv4(), ['translation']: newObj, price: value2 }]);
    setValue1('');
    setValue2('');
  };
  //==========================| On Delete Item |==========================//
  const handleDelete = (i) => {
    const newValue = field.value.filter((item) => item.id !== i.id);
    form.setFieldValue(field.name, newValue);
  };

  const handleOnChange = (value) => {
    if (value === '') {
      setValue2('');
      return;
    } else if (value.includes('-') || value.includes('e') || isNaN(value)) {
      return;
      //verefy if the value is a number
    } else if (isNaN(value) || Number(value) >= 0) {
      setValue2(Number(value));
    }
  };

  //==========================| On Change Translation |==========================//
  const handleOnChangeTranslation = (item, lang) => {
    const newItem = field.value.find((i) => i.id === item.id);
    //This will update the translation from field.value and reset the field value
    newItem.translation[lang] = event.target.value;
    form.setFieldValue(field.name, field.value);
  };
  //==========================| On Change Price |==========================//
  const handleOnChangePrice = (item, newPrice) => {
    if (newPrice.includes('-') || newPrice.includes('e') || isNaN(newPrice)) {
      return;
    }

    const newItem = field.value.find((i) => i.id === item.id);
    newItem.price = newPrice;
    form.setFieldValue(field.name, field.value);
  };

  return (
    <Grid>
      <Grid container sx={{ display: 'flex' }}>
        {/* ===========================| Text Input |=========================== */}

        <Grid item xs={5.8}>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="SocialValue1">
              {label}
            </label>
            <TextField
              fullWidth
              id="value1"
              name="value1"
              placeholder={placeholder}
              value={value1}
              onChange={(e) => setValue1(e.target.value)}
              error={Boolean(form.errors[field.name])}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
        </Grid>

        {/* ===========================| Number Input |=========================== */}

        <Grid item xs={5.8} zeroMinWidth>
          <FormControl fullWidth>
            <label style={{ marginBottom: '8px', color: color.fontColor }} htmlFor="SocialValue2">
              {labelSecondary}
            </label>
            <TextField
              fullWidth
              id="value2"
              name="value2"
              type="number"
              placeholder={placeholderSecondary}
              value={value2}
              onChange={(e) => handleOnChange(e.target.value)}
              error={Boolean(form.errors[field.name])}
              helperText={form.errors[field.name]}
              onKeyDown={(e) => {
                if (e.key === 'Enter') createItem();
                else if (['e', 'E', '+', '-'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              InputProps={{
                type: 'number',

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={createItem} edge="end">
                      <PlusOutlined />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                height: '55px',
                '& .MuiInputBase-input': {
                  padding: '15px',
                  '&::placeholder': {
                    color: color.fontColorTertiary
                  }
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <div style={{ flexGrow: 1, overflow: 'scroll', px: 1, pt: 1 }}>
        {field.value?.map((i, index) => {
          return (
            <Item
              key={i.id}
              item={i}
              defaultLanguage={defaultLanguage}
              languages={languages}
              currencyCode={currencyCode}
              handleDelete={handleDelete}
              handleOnChangeTranslation={handleOnChangeTranslation}
              handleOnChangePrice={handleOnChangePrice}
              index={index}
            />
          );
        })}
      </div>
    </Grid>
  );
}

DoubleInputList.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  labelSecondary: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholderSecondary: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};
