import { ref, listAll, deleteObject } from 'firebase/storage';
import { storage } from '../../zFirebaseConfigs/firebase';

/**
 * Verifica e exclui todos os arquivos em um diretório específico no Firebase Storage.
 * @param {object} storage - Instância do Firebase Storage.
 * @param {string} folderPath - Caminho do diretório no Firebase Storage.
 * @returns {Promise<void>}
 */
export const deleteFilesInStorageFolder = async (folderPath) => {
  try {
    const folderRef = ref(storage, folderPath);
    const existingFiles = await listAll(folderRef);
    for (const itemRef of existingFiles.items) {
      await deleteObject(itemRef);
    }
  } catch (error) {
    console.error(`Error deleting files in folder ${folderPath}: ${error.message}`);
    throw error;
  }
};
