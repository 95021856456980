import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

//Styles
import styles from './ItemPreview.module.css';
import color from 'themes/colors';

//utils
import { findLanguageName } from 'utils/languageFinder';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

//Assets
import ASSETS from 'enums/assets';
import { getImageForPath } from 'utils/imageUtils';

export default function ItemPreview({ selectedItem, defaultLanguage, languages, currencyCode, setValue }) {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  return (
    <Grid className={styles.itemPreviewContainer} style={{ backgroundColor: color.backgroundColor }}>
      <div
        className={styles.banner}
        style={{
          backgroundImage: `url(${getImageForPath(selectedItem.imageUrl, 750, 450, 'webp') || ASSETS.IMAGES.PLACEHOLDERS.PLACEHOLDER_16_BY_9})`
        }}
      >
        <div className={styles.title}>
          {capitalizeFirstLetter(selectedItem.title[selectedLanguage] || selectedItem.title[defaultLanguage])}
        </div>
      </div>

      <div style={{ display: 'flex', gap: '10px' }}>
        {languages.map((i) => (
          <button key={i} onClick={() => setSelectedLanguage(i)} style={{ color: color.fontColor }} className={styles.languageButton}>
            <img src={ASSETS.ICONS.INFO.GLOBAL} alt="icon" />
            {findLanguageName(i)}
          </button>
        ))}
      </div>

      <div className={styles.ingredients}>
        {selectedItem.ingredients[selectedLanguage]?.toLowerCase() || selectedItem.ingredients[defaultLanguage]?.toLowerCase()}
      </div>

      <div style={{ padding: '16px 0px' }}>
        <div className={styles.portionsExras}>{t('MenuItems.lb_portions')}</div>
        {selectedItem.portions.map((portion) => (
          <div key={portion.id} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', lineHeight: '24px' }}>
            <div>{portion.translation[selectedLanguage]?.toLowerCase()}</div>
            <div>
              {currencyCode} {Number(portion.price).toFixed(2)}
            </div>
          </div>
        ))}
      </div>

      {selectedItem.extras?.length > 0 && (
        <div style={{ padding: '16px 0px' }}>
          <div className={styles.portionsExras}>{t('MenuItems.lb_extras')}</div>
          {selectedItem.extras.map((extra) => (
            <div key={extra.id} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', lineHeight: '24px' }}>
              <div>{extra.translation[selectedLanguage]?.toLowerCase()}</div>
              <div>
                {currencyCode} {Number(extra.price).toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      )}

      <Button sx={{ width: '125px', ml: 'auto' }} variant="contained" onClick={() => setValue('3')}>
        {t('System.lb_edit')}
      </Button>
    </Grid>
  );
}

ItemPreview.propTypes = {
  selectedItem: PropTypes.object,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.array,
  currencyCode: PropTypes.string,
  setValue: PropTypes.func
};
