//Icons
import ic_cookie from 'assets/images/icons/ic_cookie.svg';
import ic_gavel from 'assets/images/icons/ic_gavel.svg';
import ic_lock from 'assets/images/icons/ic_lock.svg';
import ic_info from 'assets/images/icons/ic_info.svg';
import ic_dashboard from 'assets/images/icons/ic_dashboard.svg';
import ic_email from 'assets/images/icons/ic_email.svg';
import ic_global from 'assets/images/icons/ic_global.svg';
import ic_list from 'assets/images/icons/ic_list.svg';
import ic_phone from 'assets/images/icons/ic_phone.svg';
import ic_qr_code from 'assets/images/icons/ic_qr_code.svg';
import ic_restaurant from 'assets/images/icons/ic_restaurant.svg';
import ic_storefront from 'assets/images/icons/ic_storefront.svg';
//Icons-Social
import ic_facebook from 'assets/images/icons/social/ic_facebook.svg';
import ic_instagram from 'assets/images/icons/social/ic_instagram.svg';
import ic_linkedin from 'assets/images/icons/social/ic_linkedin.svg';
import ic_twitter from 'assets/images/icons/social/ic_twitter.svg';
import ic_youtube from 'assets/images/icons/social/ic_youtube.svg';
import ic_tiktok from 'assets/images/icons/social/ic_tiktok.svg';
//Icons-Actions
import ic_chevron_left from 'assets/images/icons/actions/ic_chevron_left.svg';
import ic_chevron_right from 'assets/images/icons/actions/ic_chevron_right.svg';
import ic_delete from 'assets/images/icons/actions/ic_delete.svg';
import ic_edit from 'assets/images/icons/actions/ic_edit.svg';
import ic_fullscreen from 'assets/images/icons/actions/ic_fullscreen.svg';
import ic_fullscreen_exit from 'assets/images/icons/actions/ic_fullscreen_exit.svg';
import ic_left_arrow from 'assets/images/icons/actions/ic_left_arrow.svg';
import ic_right_arrow from 'assets/images/icons/actions/ic_right_arrow.svg';
import ic_search from 'assets/images/icons/actions/ic_search.svg';
import ic_close from 'assets/images/icons/actions/ic_close.svg';

//Images
import placeholder_1_by_1 from 'assets/images/Placeholders/ic_placeholder_1_by_1.svg';
import placeholder_16_by_9 from 'assets/images/Placeholders/ic_placeholder_16_by_9.svg';
import placeholder_3_by_4 from 'assets/images/Placeholders/ic_placeholder_3_by_4.svg';

//=======================| Icons |=======================//
const ACTIONS = {
  CHEVRON_LEFT: ic_chevron_left,
  CHEVRON_RIGHT: ic_chevron_right,
  DELETE: ic_delete,
  EDIT: ic_edit,
  FULLSCREEN: ic_fullscreen,
  FULLSCREEN_EXIT: ic_fullscreen_exit,
  LEFT_ARROW: ic_left_arrow,
  RIGHT_ARROW: ic_right_arrow,
  SEARCH: ic_search,
  CLOSE: ic_close
};

const SOCIAL = {
  FACEBOOK: ic_facebook,
  INSTAGRAM: ic_instagram,
  LINKEDIN: ic_linkedin,
  TWITTER: ic_twitter,
  YOUTUBE: ic_youtube,
  TIKTOK: ic_tiktok
};

const INFO = {
  COOKIES: ic_cookie,
  GRAVEL: ic_gavel,
  LOCK: ic_lock,
  INFO: ic_info,
  DASHBOARD: ic_dashboard,
  EMAIL: ic_email,
  GLOBAL: ic_global,
  LIST: ic_list,
  PHONE: ic_phone,
  QR_CODE: ic_qr_code,
  RESTAURANT: ic_restaurant,
  STOREFRONT: ic_storefront
};
//=======================| Images |=======================//

const PLACEHOLDERS = {
  PLACEHOLDER_1_BY_1: placeholder_1_by_1,
  PLACEHOLDER_16_BY_9: placeholder_16_by_9,
  PLACEHOLDER_3_BY_4: placeholder_3_by_4
};
const BANNERS = {};

//=======================| Assets Collection |=======================//
const ASSETS = {
  ICONS: {
    ACTIONS,
    SOCIAL,
    INFO
  },
  IMAGES: {
    PLACEHOLDERS,
    BANNERS
  }
};

export default ASSETS;
