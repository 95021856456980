import QRCode from 'qrcode.react';
import PropType from 'prop-types';
import styles from './QRCodeRef.module.css';

export default function QRCodeRef({ qrCodeRef, QRCodeURL, logo }) {
  //Get the table parameter from the URL
  const urlObj = new URL(QRCodeURL);
  const tableParam = urlObj.searchParams.get('table');
  // const shortTableParam = tableParam.slice(tableParam.length - 6);

  return (
    <div className={styles.qrCodeContainer} ref={qrCodeRef}>
      <QRCode
        className={styles.qrCode}
        value={QRCodeURL}
        size={1024}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'H'}
        imageSettings={{
          src: logo,
          x: undefined,
          y: undefined,
          height: 160,
          width: 160,
          excavate: true
        }}
      />
      <div className={styles.tableId}>{tableParam}</div>
    </div>
  );
}

QRCodeRef.propTypes = {
  qrCodeRef: PropType.object,
  QRCodeURL: PropType.string,
  logo: PropType.string
};
