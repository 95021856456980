import * as Sentry from '@sentry/react';

const targets = process.env.REACT_APP_IS_LOCALHOST === 'true' ? ['localhost'] : [`${process.env.REACT_APP_SENTRY_TARGET}`];

//====================| Sentry Config |====================//
const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, // 1.0 - 100% | 0.0 - 0% | 0.5 - 50% -> percentage of transactions to capture
    tracePropagationTargets: targets, //This will only send traces to this urls
    release: `swift-menu-dashboard@${process.env.REACT_APP_VERSION}`
  });
};

export default initializeSentry;
