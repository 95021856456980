/**
 * Get Image for Path using image Service
 * @param {string} path path of image
 * @param {number} width size
 * @param {number} height size
 * @param {string} format jpg, png, webp
 * @returns url build path for image desired
 */
export function getImageForPath(path, width, height, format) {
  if (!path || !width || !height || !format) {
    return undefined;
  }
  if (path instanceof File) {
    return URL.createObjectURL(path);
  }
  const baseUrl = process.env.REACT_APP_IMAGE_SERVICE_BASE_URL.endsWith('/')
    ? process.env.REACT_APP_IMAGE_SERVICE_BASE_URL
    : `${process.env.REACT_APP_IMAGE_SERVICE_BASE_URL}/`;
  const fullUrl = `${baseUrl}image?width=${width}&height=${height}&format=${format}&path=${path}`;
  return fullUrl;
}

/**
 * Get File Extension from Type in File Object
 * @param {object} file - Object
 * @returns string extension
 */
export function getImageExtension(file) {
  if (!file || !file.type) return null;
  switch (file.type) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'image/webp':
      return 'webp';
    case 'image/svg+xml':
      return 'svg';
    default:
      return null; // Return null for unsupported types
  }
}
